import React from "react"
import "./YoutubeVideo.scss"

const DEFAULT_ENGLISH_VIDEO_LINK = "https://www.youtube.com/embed/TUlBwkFkeWk"

export const YOUTUBE_VIDEO_LINKS = {
  by: "https://www.youtube.com/embed/YC2qTvkA6Xw",
  cy_en: DEFAULT_ENGLISH_VIDEO_LINK,
  lt_en: DEFAULT_ENGLISH_VIDEO_LINK,
  lt_lt: "https://www.youtube.com/embed/pMkK8Fb1Ib8",
}

function YoutubeVideo({ videoSrc }) {
  return (
    <section className="youtube-video fullscreen">
      <iframe
        src={videoSrc + "?controls=0"}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </section>
  )
}

export default YoutubeVideo
